import React from 'react';
// import { useTranslation } from 'react-i18next';
import aboutImg from '../../assets/imgs/about-us/about-banner.png';
import SharedPageHeader from '../../common/shared-page-header/SharedPageHeader';

import './AboutUsHeader.scss';
const AboutUsHeader = () => {
  // const { t } = useTranslation();
  return (
    <>
      <SharedPageHeader headerImg={aboutImg}>
        {/* <div className="title-row1">{t('about_us.title.row_1')}</div>
        <div className="title-row">{t('about_us.title.row_2')}</div> */}
      </SharedPageHeader>
    </>
  );
};

export default AboutUsHeader;
