/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from 'react';
import { Field, useFormikContext } from 'formik';
import { Select } from 'formik-material-ui';
import MenuItem from '@material-ui/core/MenuItem';
// import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import { FormHelperText } from '@material-ui/core';
import QuestionsContext from '../../contexts/questions-context/QuestionsProvider';
import { useTranslation } from 'react-i18next';

const SelectOptionQuestion = ({ question, index }) => {
  const { touched, errors, values, setFieldError } = useFormikContext();
  const { t } = useTranslation();
  useEffect(() => {
    if (
      touched.fields &&
      touched.fields[index]?.selectOption &&
      !values.fields[index]?.selectOption &&
      question.is_required === 1
    ) {
      setFieldError(
        `fields.${index}.selectOption`,
        t('answer_questions.errors.selectOption.required')
      );
    }
  }, [values.fields[index].selectOption, errors.fields]);

  const { setErrs } = useContext(QuestionsContext);
  useEffect(() => {
    setErrs(errors);
  }, [errors]);

  return (
    <FormControl variant="outlined" error={touched?.fields?.index?.selectOption ? true : false}>
      {/* <InputLabel htmlFor={`select-option${index}`}>{question?.name}</InputLabel> */}
      <label className="select-option-title" htmlFor={`select-option${index}`}>
        {question?.name}
      </label>
      <Field
        component={Select}
        name={`fields.${index}.selectOption`}
        // variant="outlined"
        inputProps={{
          id: `select-option${index}`
        }}>
        {question?.question_chooses.map((item) => (
          <MenuItem key={item.id} value={String(item.id)}>
            {item.name}
          </MenuItem>
        ))}
      </Field>

      {touched?.fields &&
        touched.fields[index] &&
        touched.fields[index].selectOption &&
        errors?.fields &&
        errors.fields[index] &&
        errors.fields[index].selectOption && (
          <FormHelperText error>{errors.fields[index]?.selectOption}</FormHelperText>
        )}
    </FormControl>
  );
};

export default SelectOptionQuestion;
