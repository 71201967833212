/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from 'react';
import { Field, useFormikContext } from 'formik';
import { Checkbox } from 'formik-material-ui';
import { FormHelperText } from '@material-ui/core';
import QuestionsContext from '../../contexts/questions-context/QuestionsProvider';
import { useTranslation } from 'react-i18next';

const CheckBoxsQuestion = ({ question, index }) => {
  const { errors, values, setFieldError, touched, submitCount } = useFormikContext();
  const { t } = useTranslation();

  useEffect(() => {
    if (
      touched.fields &&
      touched.fields[index]?.checkBoxs &&
      !values.fields[index]?.checkBoxs[0] &&
      question.is_required === 1
    ) {
      setFieldError(`fields.${index}.checkBoxs`, t('answer_questions.errors.checkBoxs.required'));
    }
  }, [values.fields[index].checkBoxs, errors.fields, submitCount]);

  const { setErrs } = useContext(QuestionsContext);
  useEffect(() => {
    setErrs(errors);
  }, [errors]);

  return (
    <div className="checkboxs-wrap">
      <div className="check-box-title">{question?.name}</div>
      {question?.question_chooses.map((item) => (
        <div key={item.id} className="checkbox-input-wrap">
          <Field
            component={Checkbox}
            type="checkbox"
            name={`fields.${index}.checkBoxs`}
            value={String(item.id)}
            color="primary"
          />
          <div className="field-lable">{item?.name}</div>
        </div>
      ))}

      {touched?.fields &&
        touched.fields[index] &&
        touched.fields[index].checkBoxs &&
        errors?.fields &&
        errors.fields[index] &&
        errors.fields[index].checkBoxs && (
          <FormHelperText error>{errors.fields[index]?.checkBoxs}</FormHelperText>
        )}
    </div>
  );
};

export default CheckBoxsQuestion;
