import React, { useState, createContext } from 'react';

const INITIAL_VALUES = {
  loadingServices: false,
  setLoadingServices: (v) => {},
  allServices: [],
  setAllServices: (v) => {},
  allGallery: [],
  setAllGallery: (v) => {},
  selectedGallery: null,
  setSelectedGallery: (v) => {},
  selectedProject: null,
  setSelectedProject: (v) => {}
};

const ServicesContext = createContext(INITIAL_VALUES);

export const ServicesProvider = ({ children }) => {
  const [loadingServices, setLoadingServices] = useState(INITIAL_VALUES.loadingServices);
  const [allServices, setAllServices] = useState(INITIAL_VALUES.allServices);
  const [allGallery, setAllGallery] = useState(INITIAL_VALUES.allGallery);
  const [selectedGallery, setSelectedGallery] = useState(INITIAL_VALUES.selectedGallery);
  const [selectedProject, setSelectedProject] = useState(INITIAL_VALUES.selectedProject);
  return (
    <ServicesContext.Provider
      value={{
        loadingServices,
        setLoadingServices,
        allServices,
        setAllServices,
        allGallery,
        setAllGallery,
        selectedGallery,
        setSelectedGallery,
        selectedProject,
        setSelectedProject
      }}>
      {children}
    </ServicesContext.Provider>
  );
};

export default ServicesContext;
