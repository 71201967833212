import './NotFound.styles.scss';

import React from 'react';
import { Link, Typography } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import DocTitleScrollTop from '../../utils/DocTitleScrollTop';

const NotFoundPage = () => {
  DocTitleScrollTop('Not Found');

  return (
    <div className="notFoundPage">
      <div className="mfa-container">
        <div className="notFoundContent">
          <Typography variant="h3" component="h1" gutterBottom>
            Whoops, we cannot find that page.
          </Typography>
          <Typography variant="h5" component="p">
            You can always visit{' '}
            <Link to="/" color="primary" component={RouterLink}>
              homepage
            </Link>{' '}
            to get a fresh start.
          </Typography>
        </div>
      </div>
    </div>
  );
};

export default NotFoundPage;
