import React from 'react';
import { useTranslation } from 'react-i18next';

import './SharedHeroHeader.scss';
const SharedHeroHeader = ({ children }) => {
  const { i18n } = useTranslation();

  return (
    <div className={`hero-header-section ${i18n.dir()}`}>
      <div className="custom-container">
        <div className="main-wrapper">
          <div className="hero-text">{children}</div>
        </div>
      </div>
    </div>
  );
};

export default SharedHeroHeader;
