import React from 'react';
import { useTranslation } from 'react-i18next';
// import SharedHeroHeader from '../../common/shared-hero-header/SharedHeroHeader';
import SharedPageHeader from '../../common/shared-page-header/SharedPageHeader';
// import CreateProjectBanner from '../../components/create-project-banner/CreateProjectBanner';
import aboutImg from '../../assets/imgs/services/webservice.png';
import './WebSerivcePage.scss';
import WebSerivceCard from './WebSerivceCard';
const WebSerivcePage = () => {
  const { t } = useTranslation();
  return (
    <div className="web-serivce-page">
      {/* <SharedHeroHeader>
        <div className="title-row1">{t('web_serivce.main_title')}</div>
        <div className="title-row">{t('web_serivce.sub_title')}</div>
      </SharedHeroHeader> */}
      <SharedPageHeader headerImg={aboutImg}></SharedPageHeader>
      <div className="custom-container">
        <div className="serviceweb-container-warp">
          <div className="serviceweb-item">
            <div className="serviceweb-data">
              <div className="serviceweb-title">{t('web_serivce.main_title3')}</div>
              <div
                className="serviceweb-desc"
                dangerouslySetInnerHTML={{
                  __html: t('web_serivce.main_desc3')
                }}></div>
            </div>
          </div>
        </div>
      </div>
      <WebSerivceCard />
      {/* <CreateProjectBanner /> */}
    </div>
  );
};

export default WebSerivcePage;
