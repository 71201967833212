import React from 'react';
// import SharedPageHeader from '../../common/shared-page-header/SharedPageHeader';
import ContactForm from './ContactForm';
// import contactBanner from '../../assets/imgs/contact/contact-banner.jpg';
import { useTranslation } from 'react-i18next';
import SharedHeroHeader from '../../common/shared-hero-header/SharedHeroHeader';

import './ContactPage.scss';
import CreateProjectBanner from '../../components/create-project-banner/CreateProjectBanner';
import DocTitleScrollTop from '../../utils/DocTitleScrollTop';
// import FatorahBanner from '../../components/fatorah-banner/FatorahBanner';

const ContactPage = () => {
  const { t } = useTranslation();
  DocTitleScrollTop();

  return (
    <div className="contact-page">
      <SharedHeroHeader>
        <div className="title-row1">{t('contact_page.main_title')}</div>
        <div className="title-row">{t('contact_page.sub_title')}</div>
      </SharedHeroHeader>

      <div className="custom-container">
        <ContactForm />
      </div>
      {/* <FatorahBanner /> */}
      <CreateProjectBanner />
    </div>
  );
};

export default ContactPage;
