import React from 'react';

const UserIcon = ({ color }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14.5" viewBox="0 0 14 14.5">
      <path
        id="ic_single"
        d="M13,14v-.5C13,10.353,10.4,8,7,8s-6,2.353-6,5.5V14a.5.5,0,0,1-1,0v-.5A6.417,6.417,0,0,1,4.766,7.318a4,4,0,1,1,4.469,0A6.416,6.416,0,0,1,14,13.5V14a.5.5,0,0,1-1,0Z"
        fill={color ? color : '#98CAF5'}
      />
    </svg>
  );
};

export default UserIcon;
