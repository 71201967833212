import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter as Router } from 'react-router-dom';
import App from './components/app/App';
import Loading from './common/loading/Loading';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'react-whatsapp-widget/dist/index.css';
import 'react-notifications-component/dist/theme.css';
import 'animate.css/animate.min.css';
import './scss/index.scss';
import { QuestionsProivder } from './contexts/questions-context/QuestionsProvider';
import { ServicesProvider } from './contexts/services-context/ServicesProvider';

ReactDOM.render(
  <Suspense fallback={<Loading />}>
    <HelmetProvider>
      <Router>
        <QuestionsProivder>
          <ServicesProvider>
            <App />
          </ServicesProvider>
        </QuestionsProivder>
      </Router>
    </HelmetProvider>
  </Suspense>,
  document.getElementById('root')
);
