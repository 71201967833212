const routerLinks = {
  ////////////////////////////
  homePage: '/',
  ////////////////////////////
  aboutPage: '/about',
  ////////////////////////////
  galleryPage: '/gallery',
  ////////////////////////////
  galleryDetailesPage: '/gallery/gallery-details/:id',
  ////////////////////////////
  contactPage: '/contact',
  ////////////////////////////
  webServicePage: '/webservice',
  ////////////////////////////
  mobileServicePage: '/mobileservice',
  ////////////////////////////
  requestProject: '/request-project',
  ////////////////////////////
  answerQuestions: '/answer-questions/:userId',
  ////////////////////////////
  notFound: '/not-found'
};

export default routerLinks;
