import React, { lazy } from 'react';
import { Route, Switch } from 'react-router-dom';
// import { ServicesProvider } from '../../contexts/services-context/ServicesProvider';
import AboutUsPage from '../../pages/about-us-page/AboutUsPage';
import AnswerQuestions from '../../pages/answer-questions/AnswerQuestions';
import ContactPage from '../../pages/contact-page/ContactPage';
import GalleryPage from '../../pages/gallery-page/GalleryPage';
// import AboutPage from "../../pages/about-page/AboutPage";
// import CommingSoonPage from "../../pages/comming-soon-page/CommingSoonPage";
import NotFoundPage from '../../pages/not-found-page/NotFoundPage';
import OurWorkDetailes from '../../pages/our-work-detailes/OurWorkDetailes';
import RequestProjectPage from '../../pages/request-project-page/RequestProjectPage';
import MobileServicePage from '../../pages/service-page/MobileServicePage';
import WebSerivcePage from '../../pages/service-page/WebSerivcePage';
// import HomePage from "../../pages/home-page/HomePage";
import routerLinks from './routerLinks';
// import ServiceDetails from "../service-details/ServiceDetails";
const HomePage = lazy(() => import('../../pages/home-page/HomePage'));

const Routes = () => {
  return (
    <Switch>
      <Route exact path={routerLinks.homePage}>
        <HomePage />
      </Route>
      <Route exact path={routerLinks.galleryPage} component={GalleryPage} />
      <Route exact path={routerLinks.aboutPage} component={AboutUsPage} />
      <Route exact path={routerLinks.contactPage}>
        <ContactPage />
      </Route>
      <Route exact path={routerLinks.webServicePage}>
        <WebSerivcePage />
      </Route>
      <Route exact path={routerLinks.mobileServicePage}>
        <MobileServicePage />
      </Route>
      <Route exact path={routerLinks.requestProject}>
        <RequestProjectPage />
      </Route>
      <Route exact path={routerLinks.galleryDetailesPage}>
        <OurWorkDetailes />
      </Route>
      <Route exact path={routerLinks.answerQuestions}>
        <AnswerQuestions />
      </Route>

      {/* not found page */}
      <Route path="*" component={NotFoundPage} />
    </Switch>
  );
};

export default Routes;
