/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from 'react';
import { Field, useFormikContext } from 'formik';
import { FormControlLabel, FormHelperText, Radio } from '@material-ui/core';
import { RadioGroup } from 'formik-material-ui';
import QuestionsContext from '../../contexts/questions-context/QuestionsProvider';
import { useTranslation } from 'react-i18next';

const RadioButtonsQuestion = ({ question, index }) => {
  const { isSubmitting, touched, errors, values, setFieldError } = useFormikContext();
  const { t } = useTranslation();
  useEffect(() => {
    if (
      touched.fields &&
      touched.fields[index]?.radioBtn &&
      !values.fields[index]?.radioBtn &&
      question.is_required === 1
    ) {
      setFieldError(`fields.${index}.radioBtn`, t('answer_questions.errors.radioBtn.required'));
    }
  }, [values.fields[index].radioBtn, errors.fields]);

  const { setErrs } = useContext(QuestionsContext);
  useEffect(() => {
    setErrs(errors);
  }, [errors]);

  return (
    <div className="radios-wrap">
      <div className="radios-title">{question?.name}</div>

      <Field component={RadioGroup} name={`fields.${index}.radioBtn`}>
        {question.question_chooses.map((q) => (
          <FormControlLabel
            key={q.id}
            value={String(q.id)}
            control={<Radio color="primary" disabled={isSubmitting} />}
            label={q.name}
            disabled={isSubmitting}
          />
        ))}
      </Field>

      {touched?.fields &&
        touched.fields[index] &&
        touched.fields[index].radioBtn &&
        errors?.fields &&
        errors.fields[index] &&
        errors.fields[index].radioBtn && (
          <FormHelperText error>{errors.fields[index]?.radioBtn}</FormHelperText>
        )}
    </div>
  );
};

export default RadioButtonsQuestion;
