import axios from 'axios';

export const getServicesApi = async (langIsoCode) => {
  try {
    const response = await axios.get(`/Service/getServices`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        lang: langIsoCode
      }
    });
    // console.log('services res: ', response);

    if (response.status === 200 && response.data.status === 1 && response.data.data) {
      return response.data.data;
    }
  } catch (e) {
    console.log(e);
  }
};
