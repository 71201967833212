import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { Formik, Field } from 'formik';
import { TextField } from 'formik-material-ui';
import ButtonWithLoading from '../../common/button-with-loading/ButtonWithLoading';
import { useTranslation } from 'react-i18next';
import './RequestProjectForm.scss';
import { store } from 'react-notifications-component';
import requestProject from '../../../src/apis/request-project-api/requestProject';

import { Select } from 'formik-material-ui';
import { FormControl, MenuItem } from '@material-ui/core';
import { getServicesApi } from '../../apis/services';

const sleep = (ms) => new Promise((r) => setTimeout(r, ms));

const RequestProjectForm = () => {
  const { t, i18n } = useTranslation();

  const baseFormStr = 'request_project_page.request_project_form';

  const CONTACT_FORM_SCHEMA = Yup.object().shape({
    first_name: Yup.string().required(t(`${baseFormStr}.first_name.errors.required`)),
    last_name: Yup.string().required(t(`${baseFormStr}.last_name.errors.required`)),
    project_details: Yup.string().required(t(`${baseFormStr}.project_details.errors.required`)),
    phone_number: Yup.string()
      .required(t(`${baseFormStr}.phone_number.errors.required`))
      .matches(/^[0-9]+$/, t(`contact_page.contact_form.phone_number.errors.Number`)),
    project_category: Yup.string().required(t(`${baseFormStr}.project_category.errors.required`)),
    email_address: Yup.string()
      .email(t(`${baseFormStr}.email_address.errors.type_error`))
      .required(t(`${baseFormStr}.email_address.errors.required`))
  });

  const [category, setCategory] = useState([]);

  // const getCategory = () => {
  //   const headers = {
  //     lang: i18n.language
  //   };

  //   return axios.get(`http://api.shoohna.com/api/getCats`, {
  //     headers
  //   });
  // };

  useEffect(() => {
    const fetchCategory = async () => {
      try {
        const fetchedData = await getServicesApi(i18n.language);
        if (!fetchedData) {
        } else if (fetchedData?.length > 0) {
          // setCategory(fetchedData.filter((cat) => cat.type === 1));
          setCategory(fetchedData);
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchCategory();
  }, [i18n.language]);

  const renderselectitem = () => {
    return category.map(({ id, name }) => (
      <MenuItem key={id} value={id}>
        {name}
      </MenuItem>
    ));
  };

  // console.log(category);
  return (
    <Formik
      initialValues={{
        first_name: '',
        last_name: '',
        project_details: '',
        phone_number: '',
        email_address: '',
        project_category: ''
      }}
      onSubmit={async (values, { setSubmitting, resetForm }) => {
        try {
          setSubmitting(true);
          await sleep(500);
          const fetchedData = await requestProject(values, i18n.language);
          if (!fetchedData) {
            setSubmitting(false);
            store.addNotification({
              title: `${t(
                'request_project_page.request_project_form.send_btn.title_message_error'
              )}`,
              message: fetchedData.message,
              type: 'danger',
              insert: 'top',
              container: 'top-right',
              animationIn: ['animate__animated', 'animate__fadeIn'],
              animationOut: ['animate__animated', 'animate__fadeOut'],
              dismiss: {
                duration: 2000,
                showIcon: true,
                onScreen: true
              }
            });
          } else {
            setSubmitting(false);
            resetForm();
            store.addNotification({
              title: `${t('request_project_page.request_project_form.send_btn.title_message')}`,
              message: fetchedData.message,
              type: 'success',
              insert: 'top',
              container: 'top-right',
              animationIn: ['animate__animated', 'animate__fadeIn'],
              animationOut: ['animate__animated', 'animate__fadeOut'],
              dismiss: {
                duration: 2000,
                showIcon: true,
                onScreen: true
              }
            });
          }
        } catch (error) {
          setSubmitting(false);
          store.addNotification({
            title: `${t('request_project_page.request_project_form.send_btn.title_message_error')}`,
            message: 'حاول فى وقت لاحق',
            type: 'danger',
            insert: 'top',
            container: 'top-right',
            animationIn: ['animate__animated', 'animate__fadeIn'],
            animationOut: ['animate__animated', 'animate__fadeOut'],
            dismiss: {
              duration: 2000,
              showIcon: true,
              onScreen: true
            }
          });
          console.log(error);
        }

        ////////////////////////////////////////////////////////////////////////////
      }}
      validationSchema={CONTACT_FORM_SCHEMA}>
      {({ values, isSubmitting, handleSubmit, errors, touched, setSubmitting, resetForm }) => (
        <>
          <form
            onSubmit={handleSubmit}
            className="request-project-form"
            // autoComplete="off"
            id="contact-form">
            <div className="form-field-wrapper">
              <p className="field-label">{t(`${baseFormStr}.first_name.label`)}</p>
              <Field
                component={TextField}
                name="first_name"
                type="text"
                variant="outlined"
                color="primary"
                className="form-field"
                // placeholder={t(`${baseFormStr}.first_name.label`)}
              />
            </div>
            <div className="form-field-wrapper">
              <p className="field-label">{t(`${baseFormStr}.last_name.label`)}</p>
              <Field
                component={TextField}
                name="last_name"
                type="text"
                variant="outlined"
                color="primary"
                className="form-field"
                // placeholder={t(`${baseFormStr}.last_name.label`)}
              />
            </div>
            <div className="form-field-wrapper project-details-field-wrap">
              <p className="field-label">{t(`${baseFormStr}.project_details.label`)}</p>
              <Field
                component={TextField}
                name="project_details"
                type="text"
                variant="outlined"
                color="primary"
                className="form-field"
                // placeholder={t(`${baseFormStr}.project_details.label`)}
                multiline
                rows={6}
              />
            </div>
            <div className="form-field-wrapper">
              <p className="field-label">{t(`${baseFormStr}.phone_number.label`)}</p>
              <Field
                component={TextField}
                name="phone_number"
                type="text"
                variant="outlined"
                color="primary"
                className="form-field"
                // placeholder={t(`${baseFormStr}.phone_number.label`)}
              />
            </div>
            <div className="form-field-wrapper">
              <p className="field-label">{t(`${baseFormStr}.email_address.label`)}</p>
              <Field
                component={TextField}
                name="email_address"
                type="text"
                variant="outlined"
                color="primary"
                className="form-field"
                // placeholder={t(`${baseFormStr}.email_address.label`)}
              />
            </div>

            <div className="form-field-wrapper project-details-field-wrap">
              <p className="field-label">{t(`${baseFormStr}.project_category.label`)}</p>

              <FormControl>
                <Field
                  component={Select}
                  name="project_category"
                  // type="text"
                  variant="outlined"
                  color="primary"
                  className="form-field"
                  // placeholder={t(`${baseFormStr}.project_details.label`)}
                >
                  {renderselectitem()}
                </Field>
              </FormControl>
            </div>

            <ButtonWithLoading
              isSubmitting={isSubmitting}
              errors={errors}
              btnText={t(`${baseFormStr}.send_btn.title`)}
              loadingMsg={t(`${baseFormStr}.send_btn.loading`)}
              className="submit-btn submit-form-btn"
              form="contact-form"
            />

            {/* <pre>{JSON.stringify(values, null, 2)}</pre>
            <pre>{JSON.stringify(errors, null, 2)}</pre>
            <pre>{JSON.stringify(touched, null, 2)}</pre> */}
          </form>
        </>
      )}
    </Formik>
  );
};

export default RequestProjectForm;
