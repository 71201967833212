import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import aboutUs from '../../apis/contact-api/aboutUs';
import './AboutUsCart.scss';

export const AboutUsCart = () => {
  const { i18n } = useTranslation();

  const [aboutUsData, setAboutUs] = useState([]);

  useEffect(() => {
    const fetchCategory = async () => {
      try {
        const fetchedData = await aboutUs(i18n.language);
        if (!fetchedData) {
        } else {
          setAboutUs(fetchedData);
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchCategory();
  }, [i18n.language]);

  const renderAboutUs = () => {
    return (
      aboutUsData?.length > 0 &&
      aboutUsData.map((item) => (
        <div className="aboutus-item" key={item.key}>
          <div className="aboutus-data">
            <div className="aboutus-title">{item.name}</div>
            <div className="aboutus-desc" dangerouslySetInnerHTML={{ __html: item.desc }}></div>
          </div>
        </div>
      ))
    );
  };
  return (
    <div className="aboutus-container">
      <div className="aboutus-container-warp">{renderAboutUs()}</div>
    </div>
  );
};

export default AboutUsCart;
