import React from 'react';

const EmailIcon = ({ color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="23.623"
      height="15.749"
      viewBox="0 0 23.623 15.749">
      <path
        id="Path_93"
        data-name="Path 93"
        d="M22.412,85.333H1.211A1.215,1.215,0,0,0,0,86.544V99.87a1.215,1.215,0,0,0,1.211,1.211h21.2a1.215,1.215,0,0,0,1.211-1.211V86.544A1.215,1.215,0,0,0,22.412,85.333Zm-.455.909-9.449,7.087a1.267,1.267,0,0,1-1.394,0L1.666,86.242ZM16.91,93.8l5.149,6.36c.005.006.011.011.017.017H1.548c.005-.006.012-.011.017-.017L6.713,93.8a.454.454,0,0,0-.706-.572l-5.1,6.3V86.809l9.661,7.246a2.168,2.168,0,0,0,2.483,0l9.661-7.246V99.522l-5.1-6.3a.454.454,0,0,0-.706.572Z"
        transform="translate(0 -85.333)"
        fill={color ? color : '#98CAF5'}
      />
    </svg>
  );
};

export default EmailIcon;
