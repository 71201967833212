const lngs = [
  {
    code: 'en', // iso code
    // name: "English",
    name: 'En',
    country_code: 'us'
  },
  {
    code: 'ar', // iso code
    name: 'العربية',
    country_code: 'sa'
    // dir: "rtl"
  }
];

export default lngs;
