import React from 'react';

const PhoneIcon = ({ color }) => {
  return (
    <svg id="Group_22" data-name="Group 22" width="16" height="16" viewBox="0 0 16 16">
      <defs>
        <clipPath id="clip-path">
          <path
            id="ic_phone_call"
            data-name="ic_phone call"
            d="M12.275,15.914a21.391,21.391,0,0,1-7.126-4.521A17.779,17.779,0,0,1,.074,3.711,1.5,1.5,0,0,1,.343,2.289L1.78.546A1.515,1.515,0,0,1,2.06.284,1.5,1.5,0,0,1,4.154.623L6.017,3.208a1.5,1.5,0,0,1,.09,1.615L5.274,6.3A17.318,17.318,0,0,0,7.265,8.584a22.409,22.409,0,0,0,2.416,2.148l1.5-.837a1.5,1.5,0,0,1,1.611.1l2.587,1.879a1.525,1.525,0,0,1,.28.264,1.5,1.5,0,0,1-.211,2.112L13.726,15.66a1.5,1.5,0,0,1-1.451.254Z"
            fill={color ? color : '#98CAF5'}
          />
        </clipPath>
      </defs>
      <path
        id="ic_phone_call-2"
        data-name="ic_phone call"
        d="M12.275,15.914a21.391,21.391,0,0,1-7.126-4.521A17.779,17.779,0,0,1,.074,3.711,1.5,1.5,0,0,1,.343,2.289L1.78.546A1.515,1.515,0,0,1,2.06.284,1.5,1.5,0,0,1,4.154.623L6.017,3.208a1.5,1.5,0,0,1,.09,1.615L5.274,6.3A17.318,17.318,0,0,0,7.265,8.584a22.409,22.409,0,0,0,2.416,2.148l1.5-.837a1.5,1.5,0,0,1,1.611.1l2.587,1.879a1.525,1.525,0,0,1,.28.264,1.5,1.5,0,0,1-.211,2.112L13.726,15.66a1.5,1.5,0,0,1-1.451.254Z"
        fill={color ? color : '#98CAF5'}
      />
      <g id="Mask_Group_22" data-name="Mask Group 22" clipPath="url(#clip-path)">
        <g id="icon_color" data-name="icon color" transform="translate(0 0)">
          <rect
            id="_3._Color_2._Gray_2._Gray_20_background"
            data-name="3. Color / 2. Gray / 2. Gray 20 background"
            width="16"
            height="16"
            fill="none"
          />
          <rect
            id="Gray_20"
            data-name="Gray 20"
            width="16"
            height="16"
            fill={color ? color : '#98CAF5'}
          />
        </g>
      </g>
    </svg>
  );
};

export default PhoneIcon;
