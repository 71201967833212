import './Logo.scss';

import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import routerLinks from '../../components/app/routerLinks';
import { useLocation } from 'react-router-dom';

const Logo = ({ className }) => {
  const { pathname } = useLocation();
  const [LogoImage, setLogoImage] = useState("/assets/imgs/logo/logo.png")

  useEffect(() => {
    if (pathname !== routerLinks.homePage) {
      setLogoImage("/assets/imgs/logo/white-logo.png")
    } else {
      setLogoImage("/assets/imgs/logo/logo.png")
    } 
  
  }, [pathname])
  
  return (
    <Link className={className} to={routerLinks.homePage}>
      <img src={LogoImage} alt="app logo" />
    </Link>
  );
};

export default Logo;
