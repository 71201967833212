import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
// import Logo from '../../common/logo/Logo';
import face from '../../assets/imgs/hero-section/Instagram.svg';
import Twitter from '../../assets/imgs/hero-section/Twitter.svg';
import Pinterest from '../../assets/imgs/hero-section/Linkedin.svg';
// import Vk from '../../assets/imgs/hero-section/Vk.png';

import './MainAppFooter.scss';
import mainAppBarLinks from '../main-app-bar/mainAppBarLinks';
const MainAppFooter = () => {
  const { t } = useTranslation();

  const renderFooterLinks = () => {
    return mainAppBarLinks(t).map(({ id, name, link }) => (
      <li key={id}>
        <RouterLink to={link}>
          <span>{name}</span>
        </RouterLink>
      </li>
    ));
  };

  return (
    <footer className="main-app-footer">
      <div className="custom-container">
        <div className="footer-content-wrap">
          <div className="footer-about footer-shared">
            <img src="/assets/imgs/logo/logo.png" alt='logo' className="footer-logo" />
            {/* <div className="wrapper-title">{t('main_app_footer.footer_title')}</div> */}
            <p className="footer-desc">{t('main_app_footer.footer_desc')}</p>
            <div className="app-social-links">
              <div className="social-links">
                <a href="https://twitter.com/company_codein" target="_blank" rel="noreferrer">
                  <img src={Twitter} className="Image" alt="" />
                </a>
                <a
                  href="https://www.instagram.com/company_codin/?r=nametag"
                  target="_blank"
                  rel="noreferrer">
                  <img src={face} className="Image" alt="" />
                </a>
                <a
                  href="https://www.linkedin.com/in/codein-%D9%83%D9%88%D8%AF%D8%A7%D9%86-292a2a214/"
                  target="_blank"
                  rel="noreferrer">
                  <img src={Pinterest} className="Image" alt="" />
                </a>
              </div>
            </div>
          </div>
          <div className="footer-links footer-shared">
            <div className="wrapper-title">{t('main_app_footer.footer_links_title')}</div>
            <ul className="nav-links-ul">{renderFooterLinks()}</ul>
          </div>
          <div className="footer-social footer-shared">
            <div className="wrapper-title">{t('main_app_footer.footer_social_title')}</div>
            <div className="social-emails-wrap">
              <div className="social-emails">
                <div>Sales@codein.sa</div>
              </div>
              <div className="social-phone" style={{ direction: 'ltr' }}>
                <div>+966552400016</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default MainAppFooter;
