import React, { createContext, useState } from 'react';

const INITIAL_VALUES = {
  errs: {},
  setErrs: (errs) => {}
};
const QuestionsContext = createContext(INITIAL_VALUES);

export const QuestionsProivder = ({ children }) => {
  const [errs, setErrs] = useState(INITIAL_VALUES.errs);

  return (
    <QuestionsContext.Provider
      value={{
        errs,
        setErrs
      }}>
      {children}
    </QuestionsContext.Provider>
  );
};

export default QuestionsContext;
