/* eslint-disable react-hooks/exhaustive-deps */
import React, { Suspense, useContext, useState } from 'react';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { create } from 'jss';
import { ThemeProvider, StylesProvider, jssPreset, Modal, Button } from '@material-ui/core';
import rtl from 'jss-rtl';
import theme from '../../theme';
import { useTranslation } from 'react-i18next';
// import { MainAppBarProvider } from "../../contexts/main-app-bar-context/MainAppBarProvider";
import Loading from '../../common/loading/Loading';
import Routes from './Routes';
import '../../i18n';
import AppLayout from './Layout';
import { useEffect } from 'react';
import { FilterGalleryProvider } from '../../contexts/filter-gallery-context/FilterGalleryContext';
import axios from 'axios';
import ServicesContext from '../../contexts/services-context/ServicesProvider';
import { getServicesApi } from '../../apis/services';

import IconImage from '../../assets/imgs/createproject/ads1.jpg';
import adsmobile from '../../assets/imgs/createproject/ads-mobile.jpg';

import IconImageen from '../../assets/imgs/createproject/ads1-en.jpg';
import adsmobileen from '../../assets/imgs/createproject/ads-mobile-en.jpg';

// ads-mobile
axios.defaults.baseURL = 'https://api.codein.sa/api';

// function rand() {
//   return Math.round(Math.random() * 20) - 10;
// }

// function getModalStyle() {
//   const top = 50 + rand();
//   const left = 44 + rand();

//   return {
//     top: `1%`,
//     left: `2%`
//     // transform: `translate(-${top}%, -${left}%)`
//   };
// }

function App() {
  const jss = create({ plugins: [...jssPreset().plugins, rtl()] });

  const { t, i18n } = useTranslation();

  useEffect(() => {
    document.body.dir = i18n.dir();
  }, [i18n.dir()]);

  const sleep = (ms) => new Promise((r) => setTimeout(r, ms));
  const { setLoadingServices, setAllServices, setAllGallery } = useContext(ServicesContext);

  const [open, setOpen] = useState(false);
  // const [modalStyle] = React.useState(getModalStyle);

  useEffect(() => {
    const getServices = async () => {
      try {
        setLoadingServices(true);
        await sleep(500);
        const servicesData = await getServicesApi(i18n.language);
        if (servicesData.length >= 0) {
          // type === 1 ( Service type )
          // type === 2 ( Gallery type )
          const filteredServices = servicesData.filter((item) => item?.type === 1);
          const filteredGalleries = servicesData.filter((item) => item?.type === 2);
          setAllServices(filteredServices);
          setAllGallery(filteredGalleries);
          setLoadingServices(false);
        } else {
          setLoadingServices(false);
        }
      } catch (error) {
        setLoadingServices(false);
        console.log(error);
      }
    };
    getServices();
  }, [i18n.language]);
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className={`app app-${i18n.dir()}`}>
      <ThemeProvider theme={theme(i18n.dir())}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <FilterGalleryProvider>
            <StylesProvider jss={jss}>
              <Suspense fallback={<Loading />}>
                <AppLayout>
                  <Routes />
                </AppLayout>
              </Suspense>
            </StylesProvider>
          </FilterGalleryProvider>
        </MuiPickersUtilsProvider>
      </ThemeProvider>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description">
        <div className={`modal ${i18n.dir()}`}>
          <img src={IconImage} alt="fatorah ads" className="ads-web" />
          <img src={adsmobile} alt="fatorah ads" className="ads-mobile" />

          <img src={IconImageen} alt="fatorah ads" className="ads-web-en" />
          <img src={adsmobileen} alt="fatorah ads" className="ads-mobile-en" />
          <Button className="btn">
            <a
              href="http://landing.fatoorah.sa/"
              target="_blank"
              rel="noreferrer"
              onClick={handleClose}>
              <span className="btn-span">{t('create_project.Fatotah_btn')}</span>
            </a>
          </Button>
        </div>
      </Modal>
    </div>
  );
}

export default App;
