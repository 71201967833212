import React from 'react';

const GlobeIcon = ({ color }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
      <path
        id="ic_internet"
        d="M0,8A8.01,8.01,0,0,1,8,0H8A8,8,0,1,1,0,8Zm8,7c.568,0,1.193-1.152,1.59-2.955Q8.842,12,8,12t-1.59.045C6.807,13.847,7.432,15,8,15Zm1.646-.194a7,7,0,0,0,3.543-2.111,11.045,11.045,0,0,0-2.58-.563A8.749,8.749,0,0,1,9.645,14.805ZM2.812,12.694A7,7,0,0,0,6.354,14.8a8.752,8.752,0,0,1-.963-2.673A11.044,11.044,0,0,0,2.812,12.694Zm11.018-.823A6.956,6.956,0,0,0,15,8H11a21.8,21.8,0,0,1-.215,3.145A11.3,11.3,0,0,1,13.829,11.872Zm-11.659,0a11.3,11.3,0,0,1,3.044-.727A21.807,21.807,0,0,1,5,8H1A6.956,6.956,0,0,0,2.171,11.872Zm4.054-.818Q7.06,11,8,11t1.774.055A20.171,20.171,0,0,0,10,8H6A20.174,20.174,0,0,0,6.225,11.054ZM14.929,7a6.956,6.956,0,0,0-1.1-2.872,11.3,11.3,0,0,1-3.045.727A20.6,20.6,0,0,1,10.98,7ZM9.977,7a19.485,19.485,0,0,0-.2-2.054Q8.939,5,8,5T6.225,4.946A19.489,19.489,0,0,0,6.023,7ZM1.071,7H5.02a20.6,20.6,0,0,1,.2-2.145,11.294,11.294,0,0,1-3.044-.727A6.956,6.956,0,0,0,1.071,7ZM8,4q.841,0,1.589-.045C9.192,2.153,8.567,1,8,1H8C7.431,1,6.807,2.153,6.41,3.955Q7.158,4,8,4Zm2.609-.131a11.037,11.037,0,0,0,2.58-.562A7,7,0,0,0,9.646,1.2,8.752,8.752,0,0,1,10.609,3.868Zm-7.8-.562a11.032,11.032,0,0,0,2.579.562A8.755,8.755,0,0,1,6.353,1.2,7,7,0,0,0,2.812,3.306Z"
        fill={color ? color : '#98CAF5'}
      />
    </svg>
  );
};

export default GlobeIcon;
