import axios from 'axios';

const handleAnswer = (a) => {
  if (a.type === 1 || a.type === 6) {
    return a[Object.keys(a)[0]];
  } else if (a.type === 3 || a.type === 4) {
    return [a[Object.keys(a)[0]]];
  } else if (a.type === 5) {
    return [...a[Object.keys(a)[0]]];
  }
  return null;
};

const answerQuestions = async (values, userId, filesPaths) => {
  try {
    const { fields } = values;

    const fieldsWithoutFiles = fields.filter((field) => field.type !== 2);
    const answers = fieldsWithoutFiles.map((f) => ({
      question_id: f.question_id,
      // answer: f.type === 5 ? [...f.checkBoxs] : f[Object.keys(f)[0]]
      // answer: f.type === 1 ? f[Object.keys(f)[0]] : [...f[Object.keys(f)[0]]]
      answer: handleAnswer(f)
    }));

    console.log('answers: ', answers);

    const filesAnswersArr =
      filesPaths?.legnth > 0 &&
      filesPaths.map((fileObj) => ({
        question_id: fileObj.question_id,
        answer: fileObj.fileURL
      }));

    // console.log('answers: ', answers);
    const data = {
      user_id: userId,
      answers: filesAnswersArr?.legnth > 0 ? [...filesAnswersArr, ...answers] : [...answers]
    };

    // console.log('data to be submitted:', data);

    const response = await axios.post('/answerQuestions', data);

    // console.log('answer questions response : ', response);
    return response;
  } catch (e) {
    console.log(e);
  }
};

export default answerQuestions;
