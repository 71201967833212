import React, { useContext } from 'react';
import './OurWorkDetailes.scss';
import StarImage from '../../assets/imgs/our-work/star.png';
import GooglePlay from '../../assets/imgs/our-work/google-play.png';
import AppStor from '../../assets/imgs/our-work/app-store.png';
// import CreateProjectBanner from '../../components/create-project-banner/CreateProjectBanner';
import Tools from '../../assets/imgs/our-work/tools.png';
import screen from '../../assets/imgs/our-work/screen.png';
import OurWorkScreen from '../../components/our-work-components/OurWorkScreen';
import { useTranslation } from 'react-i18next';
// import { useParams } from 'react-router';
// import getProjectsDetailes from '../../apis/project-api/getProjectsDetailes';
// import DocTitleScrollTop from '../../utils/DocTitleScrollTop';
import CategorySkeleton from '../../common/CategorySkeleton';
// import FatorahBanner from '../../components/fatorah-banner/FatorahBanner';
import ServicesContext from '../../contexts/services-context/ServicesProvider';

const OurWorkDetailes = () => {
  // const location = useParams();
  // const { i18n } = useTranslation();
  // DocTitleScrollTop();
  // const [loadingDetails, setLoadingDetails] = useState(false);
  const [selectedProject, loadingServices] = useContext(ServicesContext);
  console.log('selectedProject', selectedProject);
  // useEffect(() => {
  //   const fetchCategory = async () => {
  //     try {
  //       setLoadingDetails(true);
  //       const fetchedData = await getProjectsDetailes(i18n.language, location.id);
  //       if (!fetchedData) {
  //         setLoadingDetails(false);
  //       } else {
  //         setprojectDetailes(fetchedData);
  //         setLoadingDetails(false);
  //       }
  //     } catch (error) {
  //       console.log(error);
  //       setLoadingDetails(false);
  //     }
  //   };
  //   fetchCategory();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [i18n.language, location.id]);
  const renderTechnology = () => {
    return (
      selectedProject.technology?.length > 0 &&
      selectedProject.technology.map((tech) => (
        <div className="features-item" key={tech.id}>
          <div className="features-item-image">
            <img src={tech.image} alt="tools" width="100" />
          </div>
        </div>
      ))
    );
  };

  const renderFeature = () => {
    return (
      selectedProject.features?.length > 0 &&
      selectedProject.features.map((feat) => (
        <div className="features-item" key={feat.id}>
          <div className="features-item-image">
            <img src={feat.icon} alt="features" width="50" />
          </div>
          <div className="features-item-text">
            <p>{feat.name}</p>
          </div>
        </div>
      ))
    );
  };

  const desc = { __html: selectedProject?.desc ? selectedProject.desc : '' };
  const { t } = useTranslation();
  if (true) {
    return (
      <div className="our-work">
        <div className="custom-container">
          {loadingServices ? (
            <ul className="loader-container">
              <CategorySkeleton className="loader-card" />
            </ul>
          ) : (
            <div className="image-container">
              <img src={selectedProject.image} alt="our work" />
            </div>
          )}

          <div className="our-work-title title">
            <h3 className="h1-1">{selectedProject.name}</h3>
            <p className="p-1" dangerouslySetInnerHTML={desc}></p>
          </div>

          {selectedProject?.features?.length > 0 && (
            <>
              <div className="starImage">
                <img src={StarImage} alt="star" />
              </div>
              <div className="our-work-title">
                <h3 className="h1-1">{t('gallery_detailes.Application_features')} </h3>
              </div>
              <div className="features-container">{renderFeature()}</div>
            </>
          )}

          <div className="our-work-title title download">
            <img src={screen} alt="screen" />
            <h3 className="h1-1">{t('gallery_detailes.Watch_Appcations')}</h3>
          </div>
        </div>

        {loadingServices ? (
          <ul className="loader-container">
            <CategorySkeleton className="loader-card" />
          </ul>
        ) : (
          <OurWorkScreen images={selectedProject.screens} />
        )}

        {(selectedProject?.androidLink || selectedProject?.androidLink) && (
          <>
            <div className="our-work-title title download">
              <h3 className="h1-1">{t('gallery_detailes.download')}</h3>
              <p className="p-1">
                {t('gallery_detailes.Watch_Appcations_On')} ( Google Play & App Store )
              </p>
            </div>
            <div className="download-app">
              {selectedProject?.androidLink && (
                <div>
                  <a href={selectedProject.androidLink} target="_blank" rel="noopener noreferrer">
                    <img src={GooglePlay} alt="Google Play" />
                  </a>
                </div>
              )}

              {selectedProject?.appleLink && (
                <div>
                  <a href={selectedProject.appleLink} target="_blank" rel="noopener noreferrer">
                    <img src={AppStor} alt="App Store" />
                  </a>
                </div>
              )}
            </div>
          </>
        )}

        {selectedProject?.technology?.length > 0 && (
          <div className="custom-container">
            <div className="our-work-title title download">
              <img src={Tools} alt="Tools" />
              <h3 className="h1-1">{t('gallery_detailes.Technologies_used')}</h3>
            </div>
            <div className="features-container">{renderTechnology()}</div>
          </div>
        )}
      </div>
    );
  } else {
    return <>bvvbb</>;
  }
};

export default OurWorkDetailes;
