/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import React from 'react';
import { FieldArray, Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import ButtonWithLoading from '../../common/button-with-loading/ButtonWithLoading';
import TextFieldQuestion from './TextFieldQuestion';
import FileQuestion from './FileQuestion';
import SelectOptionQuestion from './SelectOptionQuestion';
import RadioButtonsQuestion from './RadioButtonsQuestion';
import CheckBoxsQuestion from './CheckBoxsQuestion';
import saveFile from '../../apis/project-questions-apis/saveFile';
import answerQuestions from '../../apis/project-questions-apis/answerQuestions';
import { useHistory, useParams } from 'react-router-dom';
import { store } from 'react-notifications-component';
import routerLinks from '../../components/app/routerLinks';
import TextareaQuestion from './TextareaQuestion';

// const sleep = (ms) => new Promise((r) => setTimeout(r, ms));

const Questions = ({ questions }) => {
  const baseFormStr = 'request_project_page.request_project_form';
  const params = useParams();
  const history = useHistory();
  const { t } = useTranslation();
  const renderQuestions = () => {
    return questions.map((question, index) => {
      if (question?.type === 1) {
        return <TextFieldQuestion key={index} question={question} index={index} />;
      } else if (question?.type === 2) {
        return <FileQuestion key={index} question={question} index={index} />;
      } else if (question?.type === 3) {
        return <SelectOptionQuestion key={index} question={question} index={index} />;
      } else if (question?.type === 4)
        return <RadioButtonsQuestion key={index} question={question} index={index} />;
      else if (question?.type === 5) {
        return <CheckBoxsQuestion key={index} question={question} index={index} />;
      } else if (question?.type === 6) {
        return <TextareaQuestion key={index} question={question} index={index} />;
      }
    });
  };

  const handleSubmitQuestionsForm = async (values, action) => {
    // const filtered = values.fields.filter((v) => v.is_required === 1 && !Object.keys(v)[0]);
    const filtered = values.fields.filter((v) => {
      return v.is_required === 1 && !v[Object.keys(v)[0]];
    });
    if (filtered.length === 0) {
      try {
        const filesArr = values.fields.filter((item) => item.type === 2 && item.file);
        if (filesArr.length > 0) {
          const filesPaths = [];
          for (let file of filesArr) {
            const fileURL = await saveFile(file.file);
            filesPaths.push({ fileURL, type: file.type, question_id: file.question_id });
          }
          const response = await answerQuestions(values, params.userId, filesPaths);
          // console.log('data: ', answersResData);
          if (response?.status === 200 && response?.data.status === 1 && response?.data.data) {
            store.addNotification({
              title: `${t('answer_questions.success')}`,
              message: `${t('answer_questions.success')}`,
              type: 'success',
              insert: 'top',
              container: 'top-right',
              animationIn: ['animate__animated', 'animate__fadeIn'],
              animationOut: ['animate__animated', 'animate__fadeOut'],
              dismiss: {
                duration: 2000,
                showIcon: true,
                onScreen: true
              }
            });
            history.push(routerLinks.requestProject);
          } else if (
            response?.status === 200 &&
            response?.data?.status === 0 &&
            !response?.data.data
          ) {
            store.addNotification({
              title: `${t('answer_questions.done_before_title')}`,
              message: `${t('answer_questions.done_before_msg')}`,
              type: 'danger',
              insert: 'top',
              container: 'top-right',
              animationIn: ['animate__animated', 'animate__fadeIn'],
              animationOut: ['animate__animated', 'animate__fadeOut'],
              dismiss: {
                duration: 2000,
                showIcon: true,
                onScreen: true
              }
            });
            history.push(routerLinks.requestProject);
          }
        } else {
          const response = await answerQuestions(values, params.userId);
          // console.log('answer questions response : ', response);
          if (response?.status === 200 && response?.data.status === 1 && response?.data.data) {
            store.addNotification({
              title: `${t('answer_questions.success')}`,
              message: `${t('answer_questions.success')}`,
              type: 'success',
              insert: 'top',
              container: 'top-right',
              animationIn: ['animate__animated', 'animate__fadeIn'],
              animationOut: ['animate__animated', 'animate__fadeOut'],
              dismiss: {
                duration: 2000,
                showIcon: true,
                onScreen: true
              }
            });
            history.push(routerLinks.requestProject);
          } else if (
            response?.status === 200 &&
            response?.data?.status === 0 &&
            !response?.data?.data
          ) {
            store.addNotification({
              title: `${t('answer_questions.done_before_title')}`,
              message: `${t('answer_questions.done_before_msg')}`,
              type: 'danger',
              insert: 'top',
              container: 'top-right',
              animationIn: ['animate__animated', 'animate__fadeIn'],
              animationOut: ['animate__animated', 'animate__fadeOut'],
              dismiss: {
                duration: 2000,
                showIcon: true,
                onScreen: true
              }
            });
          }
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  return (
    <div className="answer-questions-page-wrap">
      <Formik
        initialValues={{
          fields: questions.map((q) => {
            if (q?.type === 1) {
              return {
                textField: '',
                question_id: q.id,
                is_required: q.is_required,
                type: q.type
              };
            } else if (q?.type === 2) {
              return {
                file: '',
                question_id: q.id,
                is_required: q.is_required,
                type: q.type
              };
            } else if (q?.type === 3) {
              return {
                selectOption: '',
                question_id: q.id,
                is_required: q.is_required,
                type: q.type
              };
            } else if (q?.type === 4) {
              return {
                radioBtn: '',
                question_id: q.id,
                is_required: q.is_required,
                type: q.type
              };
            } else if (q?.type === 5) {
              return {
                checkBoxs: [],
                question_id: q.id,
                is_required: q.is_required,
                type: q.type
                // checkBoxs: q.question_chooses.map((c) => '')
              };
            } else if (q?.type === 6) {
              return {
                textArea: '',
                question_id: q.id,
                is_required: q.is_required,
                type: q.type
              };
            }
          })
        }}
        onSubmit={handleSubmitQuestionsForm}>
        {({ values, isSubmitting, handleSubmit, errors, touched, setSubmitting, resetForm }) => (
          <>
            <form
              onSubmit={handleSubmit}
              className="answer-questions-form"
              autoComplete="off"
              id="questions-form">
              <FieldArray name="fields">
                {() => <div className="fields-wrapper">{renderQuestions()}</div>}
              </FieldArray>
              <ButtonWithLoading
                isSubmitting={isSubmitting}
                errors={errors}
                btnText={t(`${baseFormStr}.send_btn.title`)}
                loadingMsg={t(`${baseFormStr}.send_btn.loading`)}
                className="submit-btn submit-form-btn"
                form="questions-form"
              />
              {/* <h1>values</h1>
              <pre>{JSON.stringify(values, null, 2)}</pre>
              <br />
              <h1>Errors</h1>
              <pre>{JSON.stringify(errors, null, 2)}</pre>
              <br />
              <h1>Touched</h1>
              <pre>{JSON.stringify(touched, null, 2)}</pre> */}
            </form>
          </>
        )}
      </Formik>
    </div>
  );
};

export default Questions;
