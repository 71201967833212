import axios from 'axios';

const contactUs = async (values, language) => {
  try {
    const formData = new FormData();
    formData.append('name', values.name);
    formData.append('email', values.email_address);
    formData.append('message', values.msg_desc);
    formData.append('phone', values.phone_number);
    formData.append('website', values.website);

    const response = await axios.post('/contactUs', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        lang: language
      }
    });

    console.log('request project response: ', response);
    if (response.status === 200 && response.data.status === 1) {
      return response.data;
    }
  } catch (e) {
    console.log(e);
  }
};

export default contactUs;
