/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import Slider from 'react-slick';
// import OurWorkScreenItems from './ourworkscreenitem';

import './OurWorkScreen.scss';
const OurWorkScreen = ({ images }) => {
  const settings = {
    dots: false,
    arrows: true,
    rtl: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    // centerMode: true,
    infinite: true,
    centerPadding: '120px',
    autoplay: false,
    autoplaySpeed: 2000,
    cssEase: 'ease-out',
    focusOnSelect: true,
    responsive: [
      {
        breakpoint: 1366,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };
  return (
    <div className="OurWorkScreen">
      <div>
        <Slider className="custom-gallery-slider" {...settings}>
          {images?.length > 0 &&
            images.map((item) => (
              <div className="slick-item slide-wrap" key={item.id}>
                <img className="slide-img" src={item.image} alt="testimonials" />
              </div>
            ))}
        </Slider>
      </div>
    </div>
  );
};

export default OurWorkScreen;
