/* eslint-disable react-hooks/exhaustive-deps */
import { FormHelperText } from '@material-ui/core';
import { PublishRounded } from '@material-ui/icons';
import { useFormikContext } from 'formik';
import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import QuestionsContext from '../../contexts/questions-context/QuestionsProvider';

const FileQuestion = ({ question, index }) => {
  const { t } = useTranslation();
  const { values, setFieldValue, setFieldTouched, touched, setFieldError, errors } =
    useFormikContext();

  const handleFileUpload = async (name, event) => {
    // await sleep(500);
    let file = await event.target.files[0];
    setFieldValue(name, file);
    setFieldTouched(name, true);
  };

  useEffect(() => {
    if (
      touched.fields &&
      touched.fields[index]?.file &&
      !values.fields[index]?.file &&
      question.is_required === 1
    ) {
      setFieldError(`fields.${index}.file`, t('answer_questions.errors.file.required'));
    }
  }, [values.fields[index].file, errors.fields]);

  const { setErrs } = useContext(QuestionsContext);
  useEffect(() => {
    setErrs(errors);
  }, [errors]);

  return (
    <label className="upload-file-label">
      <div className="upload-file-title">{question?.name}</div>

      <div className="input-wrap">
        <input
          id={`file-${index}`}
          name={`fields.${index}.file`}
          type="file"
          onChange={(e) => handleFileUpload(`fields.${index}.file`, e)}
        />
        {values?.fields[index]?.file ? (
          values?.fields[index]?.file?.name.split(/(\\|\/)/g).pop()
        ) : (
          <>
            <span className="icon-text">
              <PublishRounded />
              Upload
            </span>
          </>
        )}
      </div>

      {touched?.fields &&
        touched.fields[index] &&
        touched.fields[index].file &&
        errors?.fields &&
        errors.fields[index] &&
        errors.fields[index].file && (
          <FormHelperText error>{errors.fields[index].file}</FormHelperText>
        )}
    </label>
  );
};

export default FileQuestion;
