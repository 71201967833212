import React from 'react';

import { useTranslation } from 'react-i18next';

// import SharedHeroHeader from '../../common/shared-hero-header/SharedHeroHeader';
import SharedPageHeader from '../../common/shared-page-header/SharedPageHeader';

import aboutImg from '../../assets/imgs/services/mobileservice.png';
import mobile from '../../assets/imgs/services/mobile.png';
import ios from '../../assets/imgs/services/ios.png';
import andriod from '../../assets/imgs/services/andriod.png';

import './MobileServicePage.scss';
// import CreateProjectBanner from '../../components/create-project-banner/CreateProjectBanner';

const MobileServicePage = () => {
  const { t } = useTranslation();
  return (
    <div className="mobile-serivce-page">
      {/* <SharedHeroHeader>
        <div className="title-row1">{t('web_serivce.main_title')}</div>
        <div className="title-row">{t('web_serivce.sub_title')}</div>
      </SharedHeroHeader> */}
      <SharedPageHeader headerImg={aboutImg}></SharedPageHeader>
      <div className="custom-container">
        <div className="servicemobile-container-warp">
          <div className="servicemobile-item">
            <div className="servicemobile-data">
              <div className="servicemobile-title">{t('web_serivce.main_title2')}</div>
              <div
                className="servicemobile-desc"
                dangerouslySetInnerHTML={{
                  __html: t('web_serivce.main_desc2')
                }}></div>
            </div>
          </div>
        </div>
      </div>

      <div className="mobile-div">
        <div className="custom-container">
          <div className="mobile-title">{t('web_serivce.mobile_desc_title')} </div>

          <div className="mobile-items">
            <div className="mobile-item">
              <img src={mobile} alt="mobile" />
              <p>{t('web_serivce.mobile_desc_opation1')}</p>
            </div>
            <div className="mobile-item">
              <img src={ios} alt="ios" />
              <p>{t('web_serivce.mobile_desc_opation2')}</p>
            </div>
            <div className="mobile-item">
              <img src={andriod} alt="andriod" />
              <p>{t('web_serivce.mobile_desc_opation3')}</p>
            </div>
          </div>
        </div>
      </div>

      <div className="custom-container">
        <div className="servicemobile-container-warp">
          <div className="servicemobile-item">
            <div className="servicemobile-data">
              <div className="servicemobile-title">{t('web_serivce.ques1')}</div>
              <div
                className="servicemobile-desc"
                dangerouslySetInnerHTML={{
                  __html: t('web_serivce.ques2')
                }}></div>
            </div>
          </div>
        </div>
      </div>
      {/* <CreateProjectBanner /> */}
    </div>
  );
};

export default MobileServicePage;
