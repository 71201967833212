/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from 'react';
import { Field, useFormikContext } from 'formik';
import { TextField } from 'formik-material-ui';
import QuestionsContext from '../../contexts/questions-context/QuestionsProvider';
import { useTranslation } from 'react-i18next';

const TextareaQuestion = ({ question, index }) => {
  const { touched, setFieldError, values, errors } = useFormikContext();
  const { t } = useTranslation();

  useEffect(() => {
    if (
      touched.fields &&
      touched.fields[index]?.textArea &&
      !values.fields[index]?.textArea &&
      question.is_required === 1
    ) {
      setFieldError(`fields.${index}.textArea`, t('answer_questions.errors.textArea.required'));
    }
  }, [values.fields[index].textArea, errors.fields, touched.fields]);

  const { setErrs } = useContext(QuestionsContext);
  useEffect(() => {
    setErrs(errors);
  }, [errors.fields]);

  return (
    <div className="text-field-wrapper">
      <p className="field-label">{question?.name}</p>
      <Field
        component={TextField}
        name={`fields.${index}.textArea`}
        type="text"
        multiline
        rows={6}
        variant="outlined"
        color="primary"
        className="form-field"
      />
    </div>
  );
};

export default TextareaQuestion;
