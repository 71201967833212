const filterList = t => [
	{
		id: 1,
		name: t("gallery_section.gallery_filter.accounting_software"),
		selected: true
	},
	{
		id: 2,
		name: t("gallery_section.gallery_filter.designing_electronic_stores")
	},
	{
		id: 3,
		name: t("gallery_section.gallery_filter.digital_marketing")
	},
	{
		id: 4,
		name: t("gallery_section.gallery_filter.website_design")
	},
	{
		id: 5,
		name: t("gallery_section.gallery_filter.phone_apps")
	}
];

export default filterList;
