import React from 'react';
import { useTranslation } from 'react-i18next';

import desgin from '../../assets/imgs/services/desgin.png';
import coding from '../../assets/imgs/services/coding.png';
import website from '../../assets/imgs/services/website.png';
import money from '../../assets/imgs/services/money.png';
import quetation from '../../assets/imgs/services/quetation.png';

const WebSerivceCard = () => {
  const { t } = useTranslation();
  return (
    <div className="WebSerivceCard">
      <div className="custom-container">
        <div className="title-div">
          <div>
            <p className="web-service-title">{t('web_serivce.card_item.title')} </p>
            <p className="desc">{t('web_serivce.card_item.desc')}</p>
          </div>
          <div>
            <img src={quetation} alt="quetation" />
          </div>
        </div>

        <div className="sub-title">{t('web_serivce.sub_card_title')}</div>

        <div className="web-service-items">
          <div className="web-service-item">
            <img src={desgin} alt="desgin" />
            <p>{t('web_serivce.card_item.desc1')}</p>
          </div>
          <div className="web-service-item">
            <img src={coding} alt="coding" />

            <p>{t('web_serivce.card_item.desc2')}</p>
          </div>
          <div className="web-service-item">
            <img src={website} alt="website" />
            <p>{t('web_serivce.card_item.desc3')}</p>
          </div>
          <div className="web-service-item">
            <img src={money} alt="money" />
            <p>{t('web_serivce.card_item.desc4')}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WebSerivceCard;
