import React from 'react';
import { useTranslation } from 'react-i18next';
// import { Link } from 'react-router-dom';
// import routerLinks from '../app/routerLinks';

import IconImage from '../../assets/imgs/createproject/ads1.jpg';
import IconImageEng from '../../assets/imgs/createproject/ads1-en.jpg';

import { Button } from '@material-ui/core';
import './FatorahBanner.scss';
const FatorahBanner = () => {
  const { t, i18n } = useTranslation();
  return (
    // <div className="custom-container">
    <div className={`FatorahBanner-container ${i18n.dir()}`}>
      <img src={IconImage} alt="fatorah banner" className="image-ar" />
      <img src={IconImageEng} alt="fatorah banner" className="image-en" />

      <Button className="btn">
        <a href="http://landing.fatoorah.sa/" target="_blank" rel="noreferrer">
          <span className="btn-span">{t('create_project.Fatotah_btn')}</span>
        </a>
      </Button>
      {/* <div className="gallery-container-warp">
   
        <div className="create-project-contain">
        </div>
        <div className="create-project-btn">
          
        </div>
      </div> */}
    </div>
    // </div>
  );
};

export default FatorahBanner;
