import React from 'react';

import './SharedPageHeader.scss';

const SharedPageHeader = ({ headerImg, children }) => {
  return (
    <div className="shared-page-header">
      <div className="title-wrap">
        <img src={headerImg} alt="header" />
        {/* <div className="custom-container">
          <div className="title">
            <div className="title-data">{children}</div>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default SharedPageHeader;
