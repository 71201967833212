import React from 'react';
import ContentLoader from 'react-content-loader';
import { useTranslation } from 'react-i18next';

const CategorySkeleton = ({ className }) => {
  const { i18n } = useTranslation();

  return (
    <div className={className}>
      <ContentLoader
        width="100%"
        height="100%"
        speed={1}
        // backgroundColor="#d5d5d5"
        // foregroundColor="#c2c2c2"
        rtl={i18n.dir() === 'rtl' ? true : false}
        // viewBox="0 0 380 70"
      >
        {/* Only SVG shapes */}
        <rect x="0" y="0" width="100%" height="100%" rx="12" ry="12" />
      </ContentLoader>
    </div>
  );
};

export default CategorySkeleton;
