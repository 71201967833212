import React from 'react';
import { useTranslation } from 'react-i18next';
import GalleryFilter from '../../common/gallery-filter/GalleryFilter';
import CreateProjectBanner from '../../components/create-project-banner/CreateProjectBanner';
import FatorahBanner from '../../components/fatorah-banner/FatorahBanner';
import GalleryItem from '../../components/gallery-page-components/GalleryItem';
import DocTitleScrollTop from '../../utils/DocTitleScrollTop';

import './GalleryPage.scss';

const GalleryPage = () => {
  DocTitleScrollTop();
  const { t } = useTranslation();
  return (
    <div className="gallery-page">
      <div className="custom-container">
        <div className="all-filter-wrapper">
          <div className="page-main-title">
            <h2>{t('gallery_section.main_title')}</h2>
          </div>
          <GalleryFilter />
        </div>
        <GalleryItem />
      </div>
      <FatorahBanner />
      <CreateProjectBanner />
    </div>
  );
};

export default GalleryPage;
