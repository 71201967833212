/* eslint-disable react-hooks/exhaustive-deps */
import './AnswerQuestions.scss';

import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { store } from 'react-notifications-component';
import getQuestions from '../../apis/project-questions-apis/getQuestions';
import Questions from './Questions';
import routerLinks from '../../components/app/routerLinks';
import Loading from '../../common/loading/Loading';

const AnswerQuestions = () => {
  const params = useParams();
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const [loadingQuestions, setLoadingQuestions] = useState(false);
  const [fetchedQuestions, setFetchedQuestions] = useState([]);

  useEffect(() => {
    const fetchQuestions = async () => {
      try {
        setLoadingQuestions(true);
        const fetchedData = await getQuestions(i18n.language, params.userId);
        if (!fetchedData) {
          setLoadingQuestions(false);
          history.push(routerLinks.notFound);
          store.addNotification({
            title: `${t('answer_questions.not_found_user.err_title')}`,
            message: `${t('answer_questions.not_found_user.err_msg')}`,
            type: 'danger',
            insert: 'top',
            container: 'top-right',
            animationIn: ['animate__animated', 'animate__fadeIn'],
            animationOut: ['animate__animated', 'animate__fadeOut'],
            dismiss: {
              duration: 2000,
              showIcon: true,
              onScreen: true
            }
          });
        } else {
          setLoadingQuestions(false);
          setFetchedQuestions(fetchedData);
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchQuestions();
  }, [i18n.language]);

  if (loadingQuestions) {
    return <Loading />;
  }

  const renderQuestions = () => {
    if (fetchedQuestions?.length === 0) {
      return (
        <div className="custom-container">
          <div className="empty-questions-page">
            <h1>No questions available Try again later</h1>
          </div>
        </div>
      );
    }

    return <Questions questions={fetchedQuestions} />;
  };

  return (
    <div className="answer-questions-page">
      <div className="custom-container">{renderQuestions()}</div>
    </div>
  );
};

export default AnswerQuestions;
