import axios from 'axios';

const requestProject = async (values, language) => {
  try {
    const formData = new FormData();
    formData.append('firstName', values.first_name);
    formData.append('lastName', values.last_name);
    formData.append('projectDesc', values.project_details);
    formData.append('phone', values.phone_number);
    formData.append('email', values.email_address);
    formData.append('cat_id', values.project_category);

    const response = await axios.post('/askProject', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        lang: language
      }
    });

    console.log('request project response: ', response);
    if (response.status === 200 && response.data.status === 1 && response.data.data) {
      return response.data;
    }
  } catch (e) {
    console.log(e);
  }
};

export default requestProject;
