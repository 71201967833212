import './RequestProjectPage.scss';

import React from 'react';
// import contactImg from '../../assets/imgs/contact/contact-img.png';
import RequestProjectForm from './RequestProjectForm';
import { useTranslation } from 'react-i18next';
import DocTitleScrollTop from '../../utils/DocTitleScrollTop';
import SharedHeroHeader from '../../common/shared-hero-header/SharedHeroHeader';

const RequestProjectPage = () => {
  const { t } = useTranslation();
  DocTitleScrollTop();

  return (
    <div className="request-project-page">
      <div className="custom-container">
        {/* <div className="contact-img-wrap">
          <img src={contactImg} alt="contact us" />
        </div> */}
        <SharedHeroHeader>
          <div className="title-row1">{t('request_project_page.main_title')}</div>
          <div className="title-row">{t('request_project_page.sub_title')}</div>
        </SharedHeroHeader>
        {/* <div className="req-project-title">{t('request_project_page.main_title')}</div> */}
        <RequestProjectForm />
      </div>
    </div>
  );
};

export default RequestProjectPage;
