/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext } from 'react';
import { useEffect } from 'react';
import ServicesContext from '../../contexts/services-context/ServicesProvider';
import './GalleryFilter.scss';

const GalleryFilter = () => {
  const { allGallery, selectedGallery, setSelectedGallery, setSelectedProject } =
    useContext(ServicesContext);
  // handle filter

  const handleFilterGallery = (serv) => {
    // const filtered = galleryFilterList.filter(g => g.id === id);
    // setFilteredList(filtered);
    // add selected
    setSelectedGallery(serv);
    setSelectedProject(null);
  };

  useEffect(() => {
    if (allGallery?.length > 0) {
      setSelectedGallery(allGallery[0]);
    }
  }, [allGallery.length]);

  const renderGalleryFilter = () =>
    allGallery?.length > 0 &&
    allGallery.map((serv) => (
      <li
        className={`filter-btn ${serv.id === selectedGallery?.id ? 'selected' : ''}`}
        key={serv.id}
        onClick={() => handleFilterGallery(serv)}>
        <span>{serv?.name}</span>
      </li>
    ));

  return <ul className="gallery-filter-list">{renderGalleryFilter()}</ul>;
};

export default GalleryFilter;
