import axios from 'axios';

const saveFile = async (file) => {
  try {
    const formData = new FormData();

    formData.append('file', file);

    const response = await axios.post('/saveFile', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });

    // console.log('save file response: ', response);
    if (response.status === 200 && response.data.status === 1 && response.data.data) {
      return response.data.data;
    }
  } catch (e) {
    console.log(e);
  }
};

export default saveFile;
