import React from 'react';
import { useTranslation } from 'react-i18next';
import MianAppBar from '../main-app-bar/MianAppBar';
import MainAppFooter from '../main-app-footer/MainAppFooter';
import ReactNotification from 'react-notifications-component';
import WhatsAppWidget from 'react-whatsapp-widget';

const AppLayout = ({ children }) => {
  const { i18n } = useTranslation();

  return (
    <div className={`app-layout app-${i18n.dir()}`}>
      <MianAppBar />
      <ReactNotification className={i18n.dir()} />
      {children}
      <MainAppFooter />
      <div>
        <a
          href="https://api.whatsapp.com/send?phone=+966552400016"
          target="_blank"
          rel="noreferrer">
          <WhatsAppWidget className="test-whatsapp" phoneNumber="+966552400016" />
        </a>
      </div>
    </div>
  );
};

export default AppLayout;
