import React from 'react';
import * as Yup from 'yup';
import { Formik, Field } from 'formik';
import { TextField } from 'formik-material-ui';
import ButtonWithLoading from '../../common/button-with-loading/ButtonWithLoading';
import { useTranslation } from 'react-i18next';
import './ContactForm.scss';
import { store } from 'react-notifications-component';
import contactUs from '../../apis/contact-api/contactUs';
import { InputAdornment } from '@material-ui/core';
import UserIcon from '../../common/icons/UserIcon';
import EmailIcon from '../../common/icons/EmailIcon';
import PhoneIcon from '../../common/icons/PhoneIcon';
import GlobeIcon from '../../common/icons/GlobeIcon';

const sleep = (ms) => new Promise((r) => setTimeout(r, ms));

const ContactForm = () => {
  const { t, i18n } = useTranslation();
  const baseFormStr = 'contact_page.contact_form';

  const CONTACT_FORM_SCHEMA = Yup.object().shape({
    name: Yup.string().required(t(`${baseFormStr}.name.errors.required`)),
    phone_number: Yup.string()
      .required(t(`${baseFormStr}.phone_number.errors.required`))
      .matches(/^[0-9]+$/, t(`${baseFormStr}.phone_number.errors.Number`)),
    email_address: Yup.string()
      .email(t(`${baseFormStr}.email_address.errors.type_error`))
      .required(t(`${baseFormStr}.email_address.errors.required`)),
    msg_desc: Yup.string().required(t(`${baseFormStr}.msg_desc.errors.required`))
  });

  return (
    <Formik
      initialValues={{
        name: '',
        email_address: '',
        phone_number: '',
        website: '',
        msg_desc: ''
      }}
      onSubmit={async (values, { setSubmitting, resetForm }) => {
        try {
          setSubmitting(true);
          await sleep(500);
          const fetchedData = await contactUs(values, i18n.language);
          if (!fetchedData) {
            setSubmitting(false);
            store.addNotification({
              title: `${t(
                'request_project_page.request_project_form.send_btn.title_message_error'
              )}`,
              message: fetchedData.message,
              type: 'danger',
              insert: 'top',
              container: 'top-right',
              animationIn: ['animate__animated', 'animate__fadeIn'],
              animationOut: ['animate__animated', 'animate__fadeOut'],
              dismiss: {
                duration: 2000,
                showIcon: true,
                onScreen: true
              }
            });
          } else {
            setSubmitting(false);
            resetForm();
            store.addNotification({
              title: `${t('request_project_page.request_project_form.send_btn.title_message')}`,
              message: fetchedData.message,
              type: 'success',
              insert: 'top',
              container: 'top-right',
              animationIn: ['animate__animated', 'animate__fadeIn'],
              animationOut: ['animate__animated', 'animate__fadeOut'],
              dismiss: {
                duration: 2000,
                showIcon: true,
                onScreen: true
              }
            });
          }
        } catch (error) {
          setSubmitting(false);
          store.addNotification({
            title: `${t('request_project_page.request_project_form.send_btn.title_message_error')}`,
            message: 'حاول فى وقت لاحق',
            type: 'danger',
            insert: 'top',
            container: 'top-right',
            animationIn: ['animate__animated', 'animate__fadeIn'],
            animationOut: ['animate__animated', 'animate__fadeOut'],
            dismiss: {
              duration: 2000,
              showIcon: true,
              onScreen: true
            }
          });
          console.log(error);
        }
      }}
      validationSchema={CONTACT_FORM_SCHEMA}>
      {({ values, isSubmitting, handleSubmit, errors, touched, setSubmitting, resetForm }) => (
        <>
          <form
            onSubmit={handleSubmit}
            className="contact-form"
            autoComplete="off"
            id="contact-form">
            <div className="form-field-wrapper project-details-field-wrap">
              <div className="contact-info">
                <div className="rigth">{t('contact_page.main_title')}</div>
                <div className="left">
                  <div>Sales@codein.sa</div>

                  <div style={{ direction: 'ltr', textAlign: 'left' }}> 
                  {/* <img src={} /> */}
                   +966552400016</div>
                </div>
              </div>
            </div>
            <div className="form-field-wrapper">
              <p className="field-label">{t(`${baseFormStr}.name.label`)}</p>
              <Field
                component={TextField}
                name="name"
                type="text"
                variant="outlined"
                color="primary"
                className="form-field"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <UserIcon />
                    </InputAdornment>
                  )
                }}
                // placeholder={t(`${baseFormStr}.first_name.label`)}
              />
            </div>
            <div className="form-field-wrapper">
              <p className="field-label">{t(`${baseFormStr}.email_address.label`)}</p>
              <Field
                component={TextField}
                name="email_address"
                type="text"
                variant="outlined"
                color="primary"
                className="form-field"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <EmailIcon />
                    </InputAdornment>
                  )
                }}
                // placeholder={t(`${baseFormStr}.email_address.label`)}
              />
            </div>

            <div className="form-field-wrapper">
              <p className="field-label">{t(`${baseFormStr}.phone_number.label`)}</p>
              <Field
                component={TextField}
                name="phone_number"
                type="text"
                variant="outlined"
                color="primary"
                className="form-field"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <PhoneIcon />
                    </InputAdornment>
                  )
                }}
              />
            </div>

            <div className="form-field-wrapper">
              <p className="field-label">{t(`${baseFormStr}.website.label`)}</p>
              <Field
                component={TextField}
                name="website"
                type="text"
                variant="outlined"
                color="primary"
                className="form-field"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <GlobeIcon />
                    </InputAdornment>
                  )
                }}
              />
            </div>

            <div className="form-field-wrapper project-details-field-wrap">
              <p className="field-label">{t(`${baseFormStr}.msg_desc.label`)}</p>
              <Field
                component={TextField}
                name="msg_desc"
                type="text"
                variant="outlined"
                color="primary"
                className="form-field"
                placeholder={t(`${baseFormStr}.msg_desc.label2`)}
                multiline
                rows={6}
              />
            </div>

            <ButtonWithLoading
              isSubmitting={isSubmitting}
              errors={errors}
              btnText={t(`${baseFormStr}.send_btn.title`)}
              loadingMsg={t(`${baseFormStr}.send_btn.loading`)}
              className="submit-btn submit-form-btn"
              form="contact-form"
            />
          </form>

          {/* <pre>{JSON.stringify(values, null, 2)}</pre>
          <pre>{JSON.stringify(errors, null, 2)}</pre>
          <pre>{JSON.stringify(touched, null, 2)}</pre> */}
        </>
      )}
    </Formik>
  );
};

export default ContactForm;
