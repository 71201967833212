import React from 'react';
import { useTranslation } from 'react-i18next';
import SharedHeroHeader from '../../common/shared-hero-header/SharedHeroHeader';
import AboutUsCart from '../../components/about-us-page-components/AboutUsCart';
import AboutUsHeader from '../../components/about-us-page-components/AboutUsHeader';
import CreateProjectBanner from '../../components/create-project-banner/CreateProjectBanner';
import like from '../../assets/imgs/bgs/icon.png'
import DocTitleScrollTop from '../../utils/DocTitleScrollTop';
import './AboutUsPage.scss';
export const AboutUsPage = () => {
  DocTitleScrollTop();
  const { t } = useTranslation();
  return (
    <div className="about-page">
      <SharedHeroHeader>
        <div className="title-row1"><div>{t('about_us.title.row_1')}
          </div> <img src={like} alt="like" width="20"/></div>
        <div className="title-row">{t('about_us.title.row_2')}</div>
      </SharedHeroHeader>
      <div className="custom-container">
        <AboutUsHeader />
        <AboutUsCart />
      </div>
      {/* <FatorahBanner /> */}
      <CreateProjectBanner />
    </div>
  );
};

export default AboutUsPage;
