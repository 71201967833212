import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import routerLinks from '../app/routerLinks';

import './CreateProjectBanner.scss';
import IconImage from '../../assets/imgs/createproject/RightArrow.png';
import { Button } from '@material-ui/core';

const CreateProjectBanner = () => {
  const { t, i18n } = useTranslation();

  return (
    <div className={`create-project-container ${i18n.dir()}`}>
      <div className="custom-container">
      <div className="gallery-container-warp">
        <div className="create-project-contain">
          <div className="text-p1">{t('hero_section.main_title.row_3')}</div>
          <div className="text-p2">{t('hero_section.main_title.row_4')}</div>
        </div>
        <div className="create-project-btn">
          <Button variant="contained" color="secondary">
            <Link to={routerLinks.requestProject}>
              <span className="btn-span">{t('create_project.create_btn')}</span>
              <img src={IconImage} alt="" />
            </Link>
          </Button>
        </div>
      </div>
      </div>

    </div>
  );
};

export default CreateProjectBanner;
