import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { Button } from '@material-ui/core';

import './GalleryItem.scss';

import IconImage from '../../assets/imgs/gallery/icon.png';
// import galleryItems from './galleryItems';
import { useTranslation } from 'react-i18next';
import ServicesContext from '../../contexts/services-context/ServicesProvider';

const GalleryItem = () => {
  const { t } = useTranslation();
  const { selectedGallery } = useContext(ServicesContext);

  const rendergalleryItems = () => {
    if (selectedGallery?.projects?.length > 0) {
      return selectedGallery.projects.map((item) => (
        <div className="gallery-item" key={item.id}>
          <div className="gallery-data">
            <div className="gallery-title">{item.name}</div>
            <div className="gallery-desc" dangerouslySetInnerHTML={{ __html: item.desc }}></div>
            <div className="gallery-btn">
              <Button variant="contained" color="secondary">
                <Link to={`/gallery/gallery-details/${item.id}`}>
                  {t('create_project.view_more')} <img src={IconImage} alt="" />
                </Link>
              </Button>
            </div>
          </div>
          <div className="gallery-img">
            <img src={item.image} alt="Gallery" height="200" />
          </div>
        </div>
      ));
    } else {
      return <h1 className="no-project">لا توجد مشاريع</h1>;
    }
  };
  return (
    <div className="gallery-container">
      <div className="gallery-container-warp">{rendergalleryItems()}</div>
    </div>
  );
};

export default GalleryItem;
