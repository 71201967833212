import React, { createContext, useState } from "react";
import { useTranslation } from "react-i18next";
import filterList from "./filterList";

const INITIAL_VALUES = {
	filteredList: [],
	setFilteredList: id => {}
};
const FilterGalleryContext = createContext(INITIAL_VALUES);

export const FilterGalleryProvider = ({ children }) => {
	const { t } = useTranslation();

	const [filteredList, setFilteredList] = useState(filterList(t));

	return (
		<FilterGalleryContext.Provider
			value={{
				filteredList,
				setFilteredList
			}}
		>
			{children}
		</FilterGalleryContext.Provider>
	);
};

export default FilterGalleryContext;
